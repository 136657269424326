import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { MailOutlined, KeyOutlined, LockOutlined } from "@ant-design/icons";

import { navigate } from "gatsby";
import { Auth } from "aws-amplify";
import { Layout, SEO } from "../components";

const ChangePasswordPage = () => {
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [error, setError] = useState("");

  const resetPassword = async () => {
    try {
      await Auth.forgotPassword(email);
      setEmailSuccess(!emailSuccess);
      if (error !== "") {
        setError("");
      }
      message.success(`Please check your email.`);
    } catch (err) {
      setError(err);
      console.log("error...: ", err);
    }
  };

  const submitNewPassword = async () => {
    try {
      // Collect confirmation code and new password, then
      await Auth.forgotPasswordSubmit(email, resetCode, newPassword)
        .then((data) => console.log("Success"))
        .catch((err) => {
          throw new Error(err);
        });
      message.success(`Password successfully reset.`);

      navigate("/");
    } catch (err) {
      setError(err);
      console.log("error...: ", err);
    }
  };

  return (
    <Layout>
      <SEO title="Change Password Page" />{" "}
      <Form className="ChangePasswordPage">
        <h1>Change Password</h1>
        {error && message.error("Something went wrong please try again")}

        {!emailSuccess && (
          <>
            <Form.Item>
              <Input
                prefix={<MailOutlined />}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button onClick={() => resetPassword()}>Reset</Button>
            </Form.Item>
          </>
        )}
        {emailSuccess && (
          <>
            <Form.Item>
              <Input
                prefix={<MailOutlined />}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Input
                prefix={<KeyOutlined />}
                placeholder="Reset Code"
                onChange={(e) => setResetCode(e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button onClick={() => submitNewPassword()}>
                Submit new password
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </Layout>
  );
};

export default ChangePasswordPage;
